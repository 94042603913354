import React from 'react';
import './Separator.css'

function Separator(props) {
    return (
        <div className='separator'>
            
        </div>
    );
}

export default Separator;